<template>
    <div class="row">
        <div class="col-xl-8" v-html="content">

        </div>
        <div class="col col-xl-4">
            <news-list ref="newsListLanding" :news="news"></news-list>
        </div>
    </div>
</template>

<script>
import EventBus from "../eventBus";
import PortsDataService from "@/service/PortsDataService";

export default {
    name: "LandingPage",
    data() {
        return {
            content: '',
            news: []
        };
    },
    async mounted() {
        await PortsDataService.news().then(response => { this.news = response.data; });
        this.content = this.$i18n.t('landingPage.content').join('');
        
        if (this.$route.query.msg) {
            EventBus.$emit("FLASHMESSAGE", {message: this.$route.query.msg, error: true});
        }
    }
};


</script>

<style scoped></style>
